<template>
  <span
    v-if="vrm"
    class="tag is-vrm has-shadow is-medium has-text-weight-semibold"
    :class="size"
  >
    {{ vrm }}
  </span>
</template>

<script>
export default {
  name: 'VrmTag',
  props: {
    vrm: {
      type: String,
      required: true,
      default: () => 'N/A'
    },
    size: {
      type: String,
      required: false,
      default: () => ''
		}
  }
}
</script>
